import React from 'react';

const Loading = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    height: '80vh', // Set the container height to full viewport height
  };

  return (
    <div style={containerStyle}>


          <img src={process.env.PUBLIC_URL + '/oculus-loading.gif'} />


    </div>
  );
};

export default Loading;
