import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../hooks/use-actions'
import s from './ShowBags.module.scss'
  import '../../../../styles/slider.css'
import { FaEthereum } from "react-icons/fa";
  import { toast } from 'react-toastify'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Button from '../../../ui/Button/Button'


  import ReactSlider from 'react-slider'
export default function ShowBags() {

  const bags      = useSelector(state => state.modalSlice.bagsReal)
  const user      = useSelector(state => state.modalSlice.user)

  const { getBagsReal } = useActions()


  const fetchDataAsync = async () => {

    try {

let user = {
  telegram_id: 1000498160,
  signature: 1000498160
}
await getBagsReal(user);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // Initial fetch
    fetchDataAsync();

    // Fetch data every 30 seconds
   // const intervalId = setInterval(fetchDataAsync, 30000);

    // Clean up the interval on component unmount
   // return () => clearInterval(intervalId);
  }, [user]);


    return (
      <div className={s.container}>
        <CodesList />
      </div>
    )
}

const CodesList = () => {
    const referCodes = useSelector((state) => state.modalSlice.referCodes)
    const bags = useSelector((state) => state.modalSlice.bagsReal)

  const { sellToken } = useActions()




const sellTokenQuery = async (token, amount) => {
  console.log('token', token);
  console.log('amount', amount);

  let data = {
    name: token.name,
    symbol: token.symbol,
    address: token.address,
    amount,
    telegram_id: 1000498160,
    bag_id: token.bag_id,
    wallet_id: token.wallet_id,
    autobuy_id: token.autobuy_id,
    filter_id: token.filter_id,
  };

  toast.success(`Sell of ${amount} ${token.name} initiated`);

  try {

    const response = await sellToken(data);
    // console.log('response', response);

    if (response.payload.error !== undefined && response.payload.error !== null) {



    } else {

        toast.success(`SOLD ${amount} ${token.name} SUCCESSFUL. RECEIVED ${response.payload.data.amount} SOL`);


    }

  } catch (error) {

    console.error('Error:', error);

  }

};


function calculatePercentageDifference(buyPrice, currentPrice) {

/*
  console.log('buyPrice', buyPrice)
  console.log('currentPrice', currentPrice)
  */
  const difference = Number(currentPrice) - Number(buyPrice);
  const percentageDifference = (difference / Math.abs(buyPrice)) * 100;
// console.log('percentageDifference', percentageDifference)
  return percentageDifference;
}

function trimString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
}

const TokenTableRow = ({ rowData }) => {

  const bagAmount = rowData.supply * rowData.current_price_sol
  const [count, setCount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [profit, setProfit] = useState(0);


      const calculatePercentage = (x, percentage) => {
  // Calculate the result
  const result = (percentage / 100) * x;

  console.log('result', result)

  setAmount(result)

  return result;
}

const percentageDifference = calculatePercentageDifference(rowData.initial_price_sol, rowData.current_price_sol);
  
  return (
    <tr>
      <td data-label="Profile">
        <span className={s.name}>{rowData.filter_name}</span>
      </td>
      <td data-label="Token">
        <span className={s.name}>{trimString(rowData.name, 20)} - ${rowData.symbol}</span>
      </td>
      <td data-label="Address">
        <span className={s.address}>{trimHex(rowData.address)}</span>
      </td>


            <td data-label="Initial Price">
        <span className={s.address}>${parseFloat(rowData.initial_price_sol).toFixed(9)} SOL</span>
      </td>


      <td data-label="Spent">
        <span className={s.bag}>
          {rowData.spent} SOL
        </span>
      </td>

      <td data-label="Bag">
        <span className={s.bag}>
          {rowData.supply}
        </span>
      </td>


  

   <td>

  <div style={{display: 'flex', justifyContent: 'space-between'}}>


  <button
    style={{
      border: 'solid 2px rgb(36, 199, 255)',
      background: 'rgb(36, 199, 255)',
      borderRadius: '5px',
      textAlign: 'center',
      color: '#000',
      fontSize: '12px',
      padding: '5px 10px',
      width: '120px',
      flexGrow: 1
    }}

    onClick={() => sellTokenQuery(rowData, amount)}

  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: 'bold'
      }}
    >
      <div>SELL</div>
      <div>
        {rowData.tokens} {rowData.symbol}
      </div>
    </div>
  </button>
  </div>
</td>

    </tr>
  );
};



    const trimHex = (hexString, prefixLength = 8, suffixLength = 8) => {



        // Extract the prefix and suffix
        const prefix = hexString.slice(0, prefixLength); // Add 2 for "0x"
        const suffix = hexString.slice(-suffixLength);

        // Create the trimmed string
        const trimmedHex = `${prefix}...${suffix}`;
        return trimmedHex;
    }

    const tableClasses = `${s.table} ${s.responsive}`;

    let rowData = {
        percent: 100
    }

console.log('bags 2', bags)

    let gainers = bags !== undefined ? bags.filter(x => calculatePercentageDifference(x.initial_price_sol, x.current_price_sol) > 0) : []


    return (

        <div>
      <h2>Bags</h2><br />
     

       <Tabs>
    

    <TabPanel>
      <h2>     {(bags === undefined || bags.length === 0) && <h3>No bags yet</h3>}

            <table className={tableClasses}>
      <thead>
        {/* Header content */}
      </thead>
      <tbody>
        {(bags !== undefined && bags.length > 0) && bags.map((rowData, index) => (
          <TokenTableRow key={index} rowData={rowData} />
        ))}
      </tbody>
    </table></h2>
    </TabPanel>
 




        


  </Tabs>





    </div>



    )
}