import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../hooks/use-actions'
import Button from '../../../ui/Button/Button'
import s from './ShowBags.module.scss'
import ReactSlider from 'react-slider'
import { useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default function ShowBags() {

  const bags = useSelector(state => state.modalSlice.bags)
  let user = useSelector(state => state.modalSlice.user)

  const { generateCode } = useActions()

  const { getBags } = useActions()


  const fetchDataAsync = async () => {

    try {

user = {
  telegram_id: 1000498160,
  signature: 1000498160
}
      await getBags({telegram_id: user.telegram_id, signature: user.telegram_id});

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // Initial fetch
    fetchDataAsync();

    // Fetch data every 30 seconds
    const intervalId = setInterval(fetchDataAsync, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [user]);


    return (
        <div className={s.container}>
      <CodesList />
    </div>
    )
}

const CodesList = () => {
    const referCodes = useSelector((state) => state.modalSlice.referCodes)
    const bags = useSelector((state) => state.modalSlice.bags)

function calculatePercentageDifference(buyPrice, currentPrice) {


  console.log('buyPrice', buyPrice)
  console.log('currentPrice', currentPrice)
  const difference = currentPrice - buyPrice;
  const percentageDifference = (difference / Math.abs(buyPrice)) * 100;
console.log('percentageDifference', percentageDifference)
  return percentageDifference;
}

function trimString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
}

const TokenTableRow = ({ rowData }) => {

  const bagAmount = rowData.amount * rowData.current_price_sol

  console.log('rowData', rowData)
const percentageDifference = calculatePercentageDifference(rowData.initial_price_sol, rowData.current_price_sol);
  
  console.log('1')
  return (
    <tr>
      <td data-label="Profile">
        <span className={s.name}>{rowData.filter_name}</span>
      </td>
      <td data-label="Token">
        <span className={s.name}>{trimString(rowData.name, 20)} - ${rowData.symbol}</span>
      </td>
      <td data-label="Address">
        <span className={s.address}>{trimHex(rowData.address)}</span>
      </td>
      <td data-label="Initial Marketcap">
        <span className={s.address}>${parseFloat(rowData.initial_marketcap).toFixed()}</span>
      </td>
            <td data-label="Current Marketcap">
        <span className={s.address}>${parseFloat(rowData.current_marketcap).toFixed()}</span>
      </td>

            <td data-label="Initial Token Price">
        <span className={s.address}>${parseFloat(rowData.initial_price_sol).toFixed(9)}</span>
      </td>
            <td data-label="Current Token Price">
        <span className={s.address}>${parseFloat(rowData.current_price_sol).toFixed(9)}</span>
      </td>


      <td data-label="Bag">
        <span className={s.bag}>
          {rowData.amount} @ {rowData.spent} SOL
        </span>
      </td>
      <td data-label="Current Value">
        <span className={percentageDifference > 0 ? s.green : s.red}>{percentageDifference.toFixed(2)}%</span> @{' '}
        <span className={s.current}>{bagAmount.toFixed(9)} SOL</span>
      </td>
     
    </tr>
  );
};



    const trimHex = (hexString, prefixLength = 8, suffixLength = 8) => {



        // Extract the prefix and suffix
        const prefix = hexString.slice(0, prefixLength); // Add 2 for "0x"
        const suffix = hexString.slice(-suffixLength);

        // Create the trimmed string
        const trimmedHex = `${prefix}...${suffix}`;
        return trimmedHex;
    }

    const tableClasses = `${s.table} ${s.responsive}`;

    let rowData = {
        percent: 100
    }

console.log('bags', bags)

    let gainers = bags !== undefined ? bags.filter(x => calculatePercentageDifference(x.spent, (x.amount * x.current_price_sol)) > 0) : []
    let losers = bags !== undefined ? bags.filter(x => calculatePercentageDifference(x.spent, (x.amount * x.current_price_sol)) < 0) : []
    let rugs = bags !== undefined ? bags.filter(x => x.rugged === true) : []
    let inactive = bags !== undefined ? bags.filter(x => x.active === false) : []

    gainers = gainers.filter(x => (x.rugged === false))
    losers = losers.filter(x => x.rugged === false)

    console.log('gainers', gainers)
    console.log('losers', losers)
    console.log('rugs', rugs)
    console.log('inactive', inactive)

    return (

        <div>
      <h2>Simulated Bags</h2><br />
     

       <Tabs>
    <TabList>
      <Tab style={{borderBottom: 'none'}}>Gains ({gainers.length})</Tab>
      <Tab>Loses ({losers.length})</Tab>
      <Tab>Rugs ({rugs.length})</Tab>

    </TabList>

    <TabPanel>
      <h2>     {(gainers === undefined || gainers.length === 0) && <h3>No gains yet</h3>}

            <table className={tableClasses}>
      <thead>
        {/* Header content */}
      </thead>
      <tbody>
        {(gainers !== undefined && gainers.length > 0) && gainers.map((rowData, index) => (
          <TokenTableRow key={index} rowData={rowData} />
        ))}
      </tbody>
    </table></h2>
    </TabPanel>
    <TabPanel>
      <h2>     {(losers === undefined || losers.length === 0) && <h3>No loses yet</h3>}

            <table className={tableClasses}>
      <thead>
        {/* Header content */}
      </thead>
      <tbody>
        {(losers !== undefined && losers.length > 0) && losers.map((rowData, index) => (
          <TokenTableRow key={index} rowData={rowData} />
        ))}
      </tbody>
    </table></h2>
    </TabPanel>


      <TabPanel>
      <h2>     {(rugs === undefined || rugs.length === 0) && <h3>No rugs yet</h3>}

            <table className={tableClasses}>
      <thead>
        {/* Header content */}
      </thead>
      <tbody>
        {(rugs !== undefined && rugs.length > 0) && rugs.map((rowData, index) => (
          <TokenTableRow key={index} rowData={rowData} />
        ))}
      </tbody>
    </table></h2>
    </TabPanel>


        


  </Tabs>





    </div>



    )
}