export const data = {
  getAllItems() {
    const items = []
    this.options.forEach((option) => items.push(...option.items))
    return items
  },



  



  options: [
    {
      id: 1,
      title: 'Authoritive',
      items: [
        {
          id: 1,
          name: 'Mutable',
          desc: ''
        },
        {
          id: 2,
          name: 'Mintable',
          desc: ''
        },
        {
          id: 3,
          name: 'Freezeable',
          desc: ''
        }
      ]
    },
    {
      id: 2,
      title: 'Socials',
      items: [
        {
          id: 4,
          name: 'Telegram',
          desc: ''
        },
        {
          id: 5,
          name: 'Twitter',
          desc: ''
        },
        {
          id: 6,
          name: 'Website',
          desc: ''
        }
      ]
    },


    {
      id: 3,
      title: 'Initial Liquidity',
      items: [
        {
          id: 7,
          name: '> 1 SOL',
          desc: ''
        },
        {
          id: 8,
          name: '> 5 SOL',
          desc: ''
        },
        {
          id: 9,
          name: '> 50 SOL',
          desc: ''
        }
      ]
    },
   
  ]
}
