import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useActions } from '../../../../hooks/use-actions'
import Button from '../../../ui/Button/Button'
import s from './AutoBuyCreator.module.scss'
import LabelName from './LabelName/LabelName'
import Options from './Option/Options'
import Wallets from './Wallet/Wallets'

export default function AutoBuyCreator() {
  const nav = useNavigate()
  const { addSavedQuery, editSavedQuery, resetQuery, editQuery } = useActions()
  const { addQuery, updateQuery, editAutoBuyQuery, addAutoBuy, updateAutoBuy } = useActions() // api
  const { savedQueries, query, autobuy, user, autobuyQuery } = useSelector((state) => state.modalSlice)
  const { id } = useParams()

console.log('savedQueries', savedQueries)
const isEdit = savedQueries.some((sq) => {
    if (sq.autobuy) {
        return sq.autobuy.some((ab) => ab.id === autobuy.id);
    }
    return false;
});

console.log('isEdit',  isEdit)

  useEffect(() => { 
  
  }, []);


  const saveQuery = () => {

    if (!autobuy.wallets.length) {
      return toast.error('Select a wallet')
    } else if (!autobuy.eth_amount) {
      return toast.error('Add an ETH amount')
    } else if (!autobuy.name) {
      return toast.error('Enter a name')
      /*
    } else if (!autobuy.gas.length) {
      return toast.error('Select a gas setting')    
      */
    } else if (autobuy.eth_amount < 0.01) {
      return toast.error('ETH amount must be at least 0.01')
    } else if (!autobuy.max_slippage) {
      return toast.error('Select max slippage')
    } else if (!autobuy.max_gas) {
      return toast.error('Select max gas')
    } else if (id == undefined || id === null) {
      return toast.error('Filter ID is empty')
    }



    // nav('/modal/show-filters')


    if (isEdit) {



    editAutoBuyQuery({
      ...autobuy,
      filterId: id
    })

    console.log('update autobuy')
    // console.log('autobuy', autobuy)
    updateAutoBuy({autobuy, telegram_id: user.telegram_id})
    toast.success('AutoBuy Updated')


    } else {





    editAutoBuyQuery({
      ...autobuy,
      filterId: id
    })

    console.log('save autobuy')
    // console.log('autobuy', autobuy)
    addAutoBuy({autobuy, telegram_id: user.telegram_id})
    toast.success('AutoBuy Added')


    }
    // resetQuery()
    nav('/modal/show-filters')

  }

  return (
    <div className={s.container}>

      <div className={s.title}>
        {isEdit ? `Update Autobuy: ${autobuy.name}` : `Create Autobuy on profile: ${autobuy.filterName}`}
      </div>
      <Wallets />
      <Options />

      <div className={s.wrapper}>
      <div className={s.autobuy}>

    
      <div className="column">
        {/* Input box with onChange event for ETH AMOUNT */}
        <input
          type="text"
          value={autobuy.eth_amount}
          onChange={(event) => editAutoBuyQuery({ ...autobuy, eth_amount: event.target.value })}

          placeholder="SOL AMOUNT"
        />
      </div>
      <div className="column">
        {/* Input box with onChange event for SLIPPAGE MAX */}
        <input
          type="text"
          value={autobuy.max_slippage}
          onChange={(event) => editAutoBuyQuery({ ...autobuy, max_slippage: event.target.value })}

          placeholder="SLIPPAGE MAX"
        />
      </div>

      <div className="column">
        {/* Input box with onChange event for TIP MAX */}
        <input
          type="text"
          value={autobuy.max_gas}
          onChange={(event) => editAutoBuyQuery({ ...autobuy, max_gas: event.target.value })}

          placeholder="TIP MAX"
        />
      </div>


      </div>
      </div>


      <div className={s.footer}>
        <input className={s.name}
          name={autobuy.name}
          value={autobuy.name}

          placeholder="NAME"
          onChange={(event) => editAutoBuyQuery({ ...autobuy, name: event.target.value })}
        />
        <Button onClick={saveQuery}>{isEdit ? 'Update' : 'Save'}</Button>
      </div>
    </div>
  )
}
