import React from 'react'
import { IoArrowBackCircleSharp } from 'react-icons/io5'
import { Outlet, useNavigate } from 'react-router-dom'

export default function ModalLayout() {
  const navigate = useNavigate();

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/modal', { replace: true });
    }
  }

  return (
    <div style={{ padding: 12 }}>
      <div style={{ display: 'inline-block', marginBottom: 16, cursor: 'pointer' }} onClick={goBack}>
        <IoArrowBackCircleSharp color='rgb(36, 199, 255)' size={26} />
      </div>
      <Outlet />
    </div>
  )
}
