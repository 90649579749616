import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useActions } from '../../../hooks/use-actions'
import Button from '../../ui/Button/Button'

import { AiOutlineAppstoreAdd, AiOutlineNumber } from 'react-icons/ai'
import { BiCategoryAlt, BiSolidWallet } from 'react-icons/bi'
import { LuClipboardList } from 'react-icons/lu'
import { Link } from 'react-router-dom'
import s from './Modal.module.scss'
import Loading from './Loading';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const decodeQueryParams = (queryString) => {
    const params = new URLSearchParams(queryString)
    // Get the user parameter and decode it
    const userParam = params.get('user');
    const decodedUser = decodeURIComponent(userParam);

    // Parse the decoded user parameter as JSON
    const userObject = JSON.parse(decodedUser);
    console.log('userObject', userObject)
    // Extract the desired fields
    const id = userObject.id;
    const firstName = userObject.first_name;
    const username = userObject.username;

    return { id, firstName, username };
}

export default function Modal() {
    const { getAllQueries, setUser } = useActions()
    const user = useSelector(state => state.modalSlice.user)
    const noob = useSelector(state => state.modalSlice.noob)
    const tier = useSelector(state => state.modalSlice.tier)
    const logs = useSelector(state => state.modalSlice.logs)
    const cmb_balance = useSelector(state => state.modalSlice.cmb_balance)
    const cmb_tier = useSelector(state => state.modalSlice.cmb_tier)
    const autobuys = useSelector(state => state.modalSlice.autobuys)
    const loading = useSelector(state => state.modalSlice.loading)
    const referral_code = useSelector(state => state.modalSlice.referral_code)
  const [isCopied, setIsCopied] = useState(false);

    const handleCopyToClipboard = () => {
    // Create a text area element to hold the text to be copied
    const textArea = document.createElement('textarea');
    textArea.value = `https://t.me/OculusSolana_Bot?start=${referral_code}`;

    // Append the text area element to the document
    document.body.appendChild(textArea);

    // Select the text within the text area and copy it to the clipboard
    textArea.select();
    document.execCommand('copy');

    // Remove the text area element from the document
    document.body.removeChild(textArea);

    // Set the state to indicate that the text has been copied
    setIsCopied(true);
  };

  const fetchDataAsync = async () => {

    try {

/*
      const { id, firstName, username } = decodeQueryParams(
        window.Telegram.WebApp.initData
      );
*/

      let id = 1000498160;
      let firstName = 'sdfsdf';
      let username = 'sdfsdfdfsf';
  



      console.log('user', user)
      console.log('user.telegram_id', user.telegram_id)

      let x = user.telegram_id === undefined ? true : false

// console.log(window.Telegram.WebApp.initData)
 

      if (x) {

        if (username !== undefined) {

          toast.success(`Logged in as ${username}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          })

        } else {

          toast.success(`Logged in as ${id}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          })

        }


      }

      setUser({
        telegram_id: id,
    
        firstName,
        hash: window.Telegram.WebApp.initData,
        username
   
      });

      let data = {
        telegram_id: id,
 
        hash: window.Telegram.WebApp.initData,
        firstName,
        username
   
      };


      await getAllQueries(data);

    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  }

    useEffect(() => {
    fetchDataAsync();
  }, []);

  const [value, setValue] = useState('on');

  const handleToggle = (event, newValue) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

    return loading === false ? (
        <div className={s.modal} style={{padding: '25px', height: '100%', textAlign: 'center'}}>


      <Link to='/modal' className={s.logo}  style={{textAlign: 'center'}}>


    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleToggle}
      aria-label="toggle button group"
    >
      <ToggleButton value="on" aria-label="Toggle ON">
        ON
      </ToggleButton>
      <ToggleButton value="off" aria-label="Toggle OFF">
        OFF
      </ToggleButton>
    </ToggleButtonGroup>

        <img src={'./oculus.png'} style={{width: '80%', marginBottom: '15px'}} />
<br />  

<>
        <br />
        <span className={s.title}>OCULUS X SOL<br /><br />PRIVATE BETA</span>
        <br /><br /> 
       </>
<br />
      </Link>

      <div style={{marginTop: '25px'}}></div>
  

      <ul className={s.nav}>
        {links.map((link) => (
          <li>
            <ModalLink
              key={link.href}
              href={link.href}
              icon={link.icon}
              text={link.text}
            />
          </li>
        ))}

      </ul>

      <ul className={s.nav}>
        {links2.map((link) => (
          <li>
            <ModalLink
              key={link.href}
              href={link.href}
              icon={link.icon}
              text={link.text}
            />
          </li>
        ))}

      </ul>

    </div>
    ) : <Loading />
}

const ModalLink = ({ href, text, icon }) => {
    return (
        <Link to={href}>
      {text}
    </Link>
    )
}


const links = [{
        href: '/modal/create-filter',
        text: 'Create profile',
        icon: <AiOutlineAppstoreAdd />
    },
    {
        href: '/modal/show-filters',
        text: 'View profiles',
        icon: <LuClipboardList />
    }
]

const links2 = [
        {
        href: '/modal/show-bags-real',
        text: 'Autobuy Bags',
        icon: <LuClipboardList />
    },
   
{
            href: '/modal/generate-wallet',
        text: 'Manage Wallets',
                icon: <LuClipboardList />
              }
]



